import request from '@/utils/request'

const ROLES_PREFIX = '/api/core/acl/roles'
const USERS_PREFIX = '/api/core/acl/users'
const PERMISSIONS_PREFIX = '/api/core/acl/permissions'
const RULES_PREFIX = '/api/core/acl/rules'

export function fetchRoles (data) {
  return request({
    url: ROLES_PREFIX,
    method: 'get',
    data
  })
}

export function addRole (data) {
  return request({
    url: ROLES_PREFIX,
    method: 'post',
    data
  })
}

export function updateRole (data) {
  return request({
    url: `${ROLES_PREFIX}/${data.id}`,
    method: 'put',
    data
  })
}

export function deleteRoleById (roleId) {
  return request({
    url: `${ROLES_PREFIX}/${roleId}`,
    method: 'delete'
  })
}

export function fetchUsers (data) {
  return request({
    url: USERS_PREFIX,
    method: 'get',
    data
  })
}

export function fetchUserById (userId) {
  return request({
    url: `${USERS_PREFIX}/${userId}`,
    method: 'get'
  })
}

export function addUser (user) {
  return request({
    url: USERS_PREFIX,
    method: 'post',
    data: user
  })
}

export function updateUser (params) {
  return request({
    url: `${USERS_PREFIX}/${params.id}`,
    method: 'put',
    data: params
  })
}

export function deleteUserById (userId) {
  return request({
    url: `${USERS_PREFIX}/${userId}`,
    method: 'delete'
  })
}

export function fetchPermissionsByUser (userId) {
  return request({
    url: `${PERMISSIONS_PREFIX}/user/${userId}`,
    method: 'get'
  })
}

export function fetchRulesByUser (userId) {
  return request({
    url: `${RULES_PREFIX}/user/${userId}`,
    method: 'get'
  })
}

export function fetchRulesBySubject (subject) {
  return request({
    url: `${RULES_PREFIX}/subject/${subject}`,
    method: 'get'
  })
}

export function saveRule (sub, obj, perm) {
  return request({
    url: RULES_PREFIX,
    method: 'post',
    data: { sub, obj, perm }
  })
}

export function copyRules (srcSubjectId, destSubjectId) {
  return request({
    url: `${RULES_PREFIX}/copy`,
    method: 'post',
    data: { srcSubjectId, destSubjectId }
  })
}
