import Vue from 'vue'
import * as api from '@/modules/core/api/acl'

export default {
  namespaced: true,

  state: {
    isLoadingRoles: false,
    roles: [],

    isLoadingUsers: false,
    users: []
  },

  getters: {
    roleByIds: state => {
      return state.roles.reduce((acc, role) => {
        acc[role.id] = role
        return acc
      }, {})
    },
    roleNameByIds: state => {
      return state.roles.reduce((names, role) => {
        names[role.id] = role.name
        return names
      }, {})
    },

    userByIds: state => {
      return state.users.reduce((names, user) => {
        names[user.id] = user
        return names
      }, {})
    },
    userNameByIds: state => {
      return state.users.reduce((names, user) => {
        names[user.id] = user.name
        return names
      }, {})
    }
  },

  mutations: {
    SET_LOADING_ROLES: (state, isLoading) => {
      Vue.set(state, 'isLoadingRoles', isLoading)
    },
    SET_ROLES: (state, roles) => {
      Vue.set(state, 'roles', roles)
    },

    SET_LOADING_USERS: (state, isLoading) => {
      Vue.set(state, 'isLoadingUsers', isLoading)
    },
    SET_USERS: (state, users) => {
      Vue.set(state, 'users', users)
    },
    ADD_USER: (state, user) => {
      const users = state.users.filter(u => u.id !== user.id)
      users.push(user)
      Vue.set(state, 'users', users)
    }
  },

  actions: {
    async fetchRoles ({ commit }) {
      try {
        commit('SET_LOADING_ROLES', true)
        const roles = (await api.fetchRoles()).data
        roles.sort((a, b) => b.predefined - a.predefined)
        commit('SET_ROLES', roles)
        return roles
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_ROLES', false)
      }
    },
    async addRole ({ commit, dispatch }, role) {
      try {
        commit('SET_LOADING_ROLES', true)
        const roleId = (await api.addRole(role)).data
        await dispatch('fetchRoles')
        return roleId
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_ROLES', false)
      }
    },
    async updateRole ({ commit, dispatch }, params) {
      try {
        commit('SET_LOADING_ROLES', true)
        await api.updateRole(params)
        await dispatch('fetchRoles')
        return params.id
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_ROLES', false)
      }
    },
    async deleteRoleById ({ commit, dispatch }, roleId) {
      try {
        commit('SET_LOADING_ROLES', true)
        await api.deleteRoleById(roleId)
        await dispatch('fetchRoles')
        await dispatch('fetchUsers')
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_ROLES', false)
      }
    },

    async fetchUsers ({ commit }) {
      try {
        commit('SET_LOADING_USERS', true)
        const users = (await api.fetchUsers()).data
        commit('SET_USERS', users)
        return users
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_USERS', false)
      }
    },
    async fetchUserById ({ commit }, userId) {
      try {
        commit('SET_LOADING_USERS', true)
        const user = (await api.fetchUserById(userId)).data
        commit('ADD_USER', user)
        return user
      } catch (e) {
        return Promise.reject(e)
      } finally {
        commit('SET_LOADING_USERS', false)
      }
    },
    async addUser ({ commit, dispatch }, user) {
      try {
        commit('SET_LOADING_USERS', true)
        const userId = (await api.addUser(user)).data
        await dispatch('fetchUserById', userId)
        return userId
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_USERS', false)
      }
    },
    async updateUser ({ commit, dispatch }, params) {
      try {
        commit('SET_LOADING_USERS', true)
        await api.updateUser(params)
        await dispatch('fetchUserById', params.id)
        return params.id
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_USERS', false)
      }
    },
    async deleteUserById ({ commit, dispatch }, userId) {
      try {
        commit('SET_LOADING_USERS', true)
        await api.deleteUserById(userId)
        await dispatch('fetchUsers')
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_USERS', false)
      }
    }
  }
}
