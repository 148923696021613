import request from '@/utils/request'

const PREFIX = '/api/core/factory/map'

export function fetchFactoryMap () {
  return request({
    url: PREFIX,
    method: 'get'
  })
}

export function saveFactoryMap (map) {
  return request({
    url: PREFIX,
    method: 'post',
    data: map
  })
}

export default {
  fetchFactoryMap,
  saveFactoryMap
}
