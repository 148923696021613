import axios from 'axios'
import config from '@/config'

const service = axios.create({
  timeout: config.API_REQUEST_TIMEOUT
})

export const parseError = function (error) {
  let message = error.toString()
  if (error.response && error.response.data) {
    const payload = error.response.data
    if (payload.errors && payload.errors.message) {
      message = payload.errors.message
    }
  }
  return message
}

export default service
