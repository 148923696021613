import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import { cloneDeep } from 'lodash'
import * as api from '@/modules/supervisor/api/productionPlans'
import {
  getPlanByDate,
  getPlansInPeriodByTankId,
  getDateBoundariesByPlans
} from '@/modules/supervisor/use/productionPlans'

const initialForm = {
  seasonDates: [undefined, undefined],
  initialFishAmount: undefined,
  initialFishWeight: undefined,
  initialFishBiomass: undefined,
  mortalityPercent: 3,
  tankId: '',
  feedingPlanId: null
}

export default {
  namespaced: true,
  state: {
    form: cloneDeep(initialForm),
    all: [],
    plans: [],
    pointerDate: null
  },
  getters: {
    getField,
    allByIds: state => {
      return state.all.reduce((acc, plan) => {
        acc[plan.id] = plan
        return acc
      }, {})
    },
    planByIds: state => {
      return state.plans.reduce((acc, plan) => {
        acc[plan.id] = plan
        return acc
      }, {})
    },
    planById: (state, getters) => planId => getters.planByIds[planId],
    planByTankWithDate: (state, getters) => ({ tankId, date }) => {
      return getPlanByDate(getters.plansByTankId(tankId), date)
    },
    hasPlanByTankWithDate: (state, getters) => ({ tankId, date }) => {
      const tankPlan = getters.planByTankWithDate({ tankId, date })
      return !!tankPlan
    },
    maxSeasonPeriod: state => getDateBoundariesByPlans(state.plans),
    plansByTankId: state => tankId => {
      return state.plans.filter(plan => plan.tankId === tankId)
    },
    plansInPeriodByTankId: state => ({ periodStart, periodEnd, tankId }) => {
      return getPlansInPeriodByTankId({
        plans: state.plans,
        periodStart,
        periodEnd,
        tankId
      })
    }
  },
  mutations: {
    updateField,
    SET_ALL_PLANS: (state, plans) => {
      Vue.set(state, 'all', plans)
    },
    SET_PRODUCTION_PLANS: (state, plans) => {
      Vue.set(state, 'plans', plans)
    },
    ADD_PRODUCTION_PLAN: (state, plan) => {
      const addPlanTo = (plan, plans) => {
        const idx = plans.findIndex(p => p.id === plan.id)
        if (idx !== -1) {
          plans.splice(idx, 1, plan)
        } else {
          plans.push(plan)
        }
        return plans
      }
      state.plans = addPlanTo(plan, state.plans)
      state.all = addPlanTo(plan, state.all)
    },
    DELETE_PLAN_BY_ID: (state, planId) => {
      let idx = state.plans.findIndex(plan => plan.id === planId)
      if (idx !== -1) {
        state.plans.splice(idx, 1)
      }
      idx = state.all.findIndex(plan => plan.id === planId)
      if (idx !== -1) {
        state.all.splice(idx, 1)
      }
    },
    SET_POINTER_DATE: (state, date) => {
      // FIXME: trick to call change watcher
      Vue.set(state, 'pointerDate', null)
      Vue.set(state, 'pointerDate', date)
    },
    SET_PLAN_FORM: (state, form) => {
      Vue.set(state, 'form', cloneDeep(form))
    },
    RESET_PLAN_FORM: state => {
      Vue.set(state, 'form', cloneDeep(initialForm))
    }
  },
  actions: {
    async fetchAllPlans ({ commit }) {
      try {
        const response = await api.fetchAllPlans()
        commit('SET_ALL_PLANS', response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchPlansInPeriod ({ commit }, { periodStart, periodEnd }) {
      try {
        var response = await api.fetchPlansInPeriod({ periodStart, periodEnd })
      } catch (error) {
        return Promise.reject(error)
      }
      commit('SET_PRODUCTION_PLANS', response.data)
    },
    async fetchPlanById ({ commit }, planId) {
      try {
        var response = await api.fetchPlanById(planId)
      } catch (error) {
        return Promise.reject(error)
      }
      commit('ADD_PRODUCTION_PLAN', response.data)
    },
    async createPlan ({ commit }, plan) {
      try {
        var response = await api.createPlan(plan)
      } catch (error) {
        return Promise.reject(error)
      }
      return response.data
    },
    async updatePlan ({ commit }, { planId, plan }) {
      try {
        await api.updatePlanById(planId, plan)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async deletePlanById ({ state, commit }, planId) {
      try {
        await api.deletePlanById(planId)
        commit('DELETE_PLAN_BY_ID', planId)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async saveCorrection (_, correction) {
      try {
        await api.saveCorrection(correction)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async deleteCorrection (_, correction) {
      try {
        await api.deleteCorrectionById(correction.id)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
}
