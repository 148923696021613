<template>
  <page-splash
    :header="$t('core.label')"
    :label="$t('core.statuses.loading')"
    :message="message"
    :animated="true"
    :cover-toolbar="true"
    :cover-navbar="true"
  />
</template>

<script>
import PageSplash from '@/modules/core/components/PageSplash'

export default {
  components: {
    PageSplash
  },
  computed: {
    message () {
      const action = this.$store.state.core.app.loadings[0]
      return action ? this.$t(`core.loading.${action}`) : ''
    }
  }
}
</script>
