<template>
  <div id="app">
    <app-splash-screen v-show="isLoading" />
    <router-view v-show="!isLoading" />
  </div>
</template>

<script>
import AppSplashScreen from '@/modules/core/components/AppSplashScreen'
import { collectClickAnalytics } from '@/plugins/analytics'

export default {
  name: 'App',
  components: {
    AppSplashScreen
  },
  computed: {
    isLoading () {
      return this.$store.state.core.app.loadings.length > 0
    }
  },
  created () {
    document.querySelector(':root').style.setProperty(
      '--scrollbar-width',
      this.getScrollBarWidth() + 'px'
    )
    document.addEventListener('mousedown', collectClickAnalytics)
  },
  beforeDestroy () {
    document.removeEventListener('mousedown', collectClickAnalytics)
  },
  methods: {
    getScrollBarWidth () {
      const outer = document.createElement('div')
      outer.className = 'el-scrollbar__wrap'
      outer.style.visibility = 'hidden'
      outer.style.width = '100px'
      outer.style.position = 'absolute'
      outer.style.top = '-9999px'
      document.body.appendChild(outer)

      const widthNoScroll = outer.offsetWidth
      outer.style.overflow = 'scroll'

      const inner = document.createElement('div')
      inner.style.width = '100%'
      outer.appendChild(inner)

      const widthWithScroll = inner.offsetWidth
      outer.parentNode.removeChild(outer)
      return widthNoScroll - widthWithScroll
    }
  }
}
</script>

<style lang="scss">
#app {
  width: 100vw;
  height: 100vh;
}
</style>
